import { Component, OnInit, Input } from '@angular/core';
import { PopoverController, NavController, ActionSheetController } from '@ionic/angular';
import { AppState } from 'src/app/ngrx';
import { Store } from '@ngrx/store';
import * as SessionActions from 'src/app/ngrx/session/session.actions';
import { Lead } from 'src/app/classes/lead/lead';
import * as fromSessionSelectors from 'src/app/ngrx/session/session.selectors';
import { first } from 'rxjs/operators';
import { User } from 'src/app/classes/user/user';

@Component({
  selector: 'app-la-popover',
  templateUrl: './la-popover.component.html',
  styleUrls: ['./la-popover.component.scss'],
})
export class LaPopoverComponent implements OnInit {
  // tslint:disable-next-line: variable-name
  public _lead: Lead;
  // tslint:disable-next-line: variable-name
  public _isAdmin: boolean;
  // tslint:disable-next-line: variable-name
  public _isTrusted: boolean;
  // tslint:disable-next-line: variable-name
  public _isPipeline: boolean;

  private btns = [];

  // tslint:disable-next-line: variable-name
  public _user: User;

  @Input() set lead(val: Lead) {
    // console.log(val);
    this._lead = (val !== undefined && val !== null) ? val : new Lead();
  }

  @Input() set isPipeline(val: boolean) {
    this._isPipeline = val;
  }

  constructor(
    private popoverController: PopoverController,
    private store: Store<AppState>,
    private actionSheetController: ActionSheetController,
    private navCtrl: NavController
  ) { }

  ngOnInit() {

    this.store.dispatch(SessionActions.GetUsers());

    this.store.select(fromSessionSelectors.SelectCurrentUser).pipe(first()).subscribe((user) => {
      this._isAdmin = (Number(user.user_level) === 0);
      this._isTrusted = (Number(user.user_level) === 2);
    });

    this.store.select(fromSessionSelectors.SelectUsers).pipe(first()).subscribe(async (users) => {
      users.forEach(u => {
        this.btns.push({
          text: u.first_name + ' ' + u.last_name,
          icon: 'return-up-back-outline',
          handler: async () => {
            await this.store.dispatch(SessionActions.AssignLead({ lead: this._lead, user: u }));
            await this.popoverController.dismiss('close');
          }
        });
      });
      this.btns.push({
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      });
    });
  }

  ionViewDidEnter() {
  }

  async dismissPopover() {
    await this.popoverController.dismiss();
  }

  async call() {
    this.store.dispatch(SessionActions.CallLead({ lead: this._lead }));
    await this.popoverController.dismiss();
  }

  async claimLead() {
    this.store.select(fromSessionSelectors.SelectCurrentUser).pipe(first()).subscribe((user) => {
      this.store.dispatch(SessionActions.AssignLead({ lead: this._lead, user }));
    });
    this.store.dispatch(SessionActions.DisplayLead({ lead: null }));
    await this.popoverController.dismiss('close');
  }

  async editLead() {
    // this.navCtrl.navigateForward(['/app/lead/', this._lead.id]);
    this.store.dispatch(SessionActions.DisplayLeadTab({ tab: 'edit' }));
    await this.popoverController.dismiss();
  }

  async closeLead() {
    this.store.dispatch(SessionActions.DisplayLead({ lead: null }));
    this.store.dispatch(SessionActions.DisplayLeadTab({ tab: 'close' }));
    await this.popoverController.dismiss();
  }

  async transferLead() {

    const actionSheet = await this.actionSheetController.create({
      header: 'Transfer ' + this._lead?.first_name + ' ' + this._lead?.last_name,
      // subHeader: 'move ' + this._lead?.first_name + ' ' + this._lead?.last_name + ' to another user',
      buttons: this.btns
    });

    await actionSheet.present();

  }
}
