import { ConversationsService } from "src/app/services/data/conversations/conversations.service";
import {
  Lead,
  CloseData,
  ContactNumber,
  ContactEmail,
  ContactAddress,
} from "src/app/classes/lead/lead";
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import {
  ActionSheetController,
  IonDatetime,
  IonInput,
  IonTextarea,
  ModalController,
  Platform,
  PopoverController,
  ToastController,
} from "@ionic/angular";
import { AppState } from "src/app/ngrx";
import { Store, ActionsSubject } from "@ngrx/store";
import * as fromSessionSelectors from "src/app/ngrx/session/session.selectors";
import * as SessionActions from "src/app/ngrx/session/session.actions";
import {
  takeUntil,
  first,
  distinctUntilChanged,
  debounce,
  takeWhile,
  single,
  map,
  filter,
  tap,
  isEmpty,
  catchError,
} from "rxjs/operators";
import { Subject, Observable, of, timer } from "rxjs";
import { MessagesService } from "src/app/services/data/messages/messages.service";
import { Message } from "src/app/classes/message/message";
import { Actions, ofType } from "@ngrx/effects";
import { LaPopoverComponent } from "./la-popover/la-popover.component";
import { User } from "src/app/classes/user/user";
import { WsMessagingService } from "src/app/services/ws-messaging.service";

@Component({
  selector: "app-lead-accordian",
  templateUrl: "./lead-accordian.component.html",
  styleUrls: ["./lead-accordian.component.scss"],
})
export class LeadAccordianComponent implements OnInit, OnDestroy {
  public closedata = new CloseData({
    disposition: null,
    reason: null,
    scheduled: new Date().toISOString(),
    product: null,
    attribute1: null,
  });

  // tslint:disable-next-line:variable-name
  public _lead: Lead;
  // tslint:disable-next-line:variable-name
  public _editlead: Lead;
  // tslint:disable-next-line:variable-name
  public _color: string;
  // tslint:disable-next-line:variable-name
  public _selectedButton = "notes";
  // tslint:disable-next-line:variable-name
  public _message: string;
  // tslint:disable-next-line: variable-name
  public _phone: ContactNumber;
  // tslint:disable-next-line:variable-name
  public _email: ContactEmail;
  // tslint:disable-next-line: variable-name
  public _address: ContactAddress;
  // tslint:disable-next-line:variable-name
  public _attention: boolean;
  public messages = new Array<Message>();
  // tslint:disable-next-line:variable-name
  public _notes = "";
  public vm: HTMLMediaElement;
  // tslint:disable-next-line: variable-name
  public _user: User;
  // tslint:disable-next-line: variable-name
  public _claimingUser: User;
  // tslint:disable-next-line: variable-name
  public _isPipeline: boolean;
  // tslint:disable-next-line: variable-name
  public _editNotes = false;
  // tslint:disable-next-line: variable-name
  public _addNote = false;
  // tslint:disable-next-line: variable-name
  public _newNote = "";

  private cssStyle = getComputedStyle(document.documentElement);
  public lightcolor = this.cssStyle.getPropertyValue("--ion-color-light");
  public secondarycolor = this.cssStyle.getPropertyValue(
    "--ion-color-secondary"
  );

  @ViewChild("newnote", { static: false }) newnoteElement: IonInput;
  @ViewChild("editnote", { static: false }) editnoteElement: IonTextarea;
  @ViewChild("messageta", { static: false }) messagetaElement: IonTextarea;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() set lead(val: Lead) {
    // console.log(val);
    this._lead = val !== undefined && val !== null ? val : new Lead();
  }

  @Input() set color(val: string) {
    this._color = val;
  }

  @Input() set isPipeline(val: boolean) {
    this._isPipeline = val;
  }

  constructor(
    private store: Store<AppState>,
    public actionSheetController: ActionSheetController,
    private messageService: MessagesService,
    private conversationService: ConversationsService,
    private updates$: Actions,
    private popoverController: PopoverController,
    public modalController: ModalController,
    private platform: Platform,
    private toastController: ToastController,
    private wsMessagingService: WsMessagingService
  ) {}

  ionViewWillLeave() {
    this.wsMessagingService.leaveRoom(this._lead.conversation.id);
  }

  ionViewWillEnter() {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (window.history.state.modal) {
      history.back();
    }
  }

  ionViewDidEnter() {
    const modalState = {
      modal: true,
      desc: "placeholder state for modal",
    };
    history.pushState(modalState, null);

    if (!this._isPipeline) {
      this.store
        .select(fromSessionSelectors.SelectLeads)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((l) => {
          this._lead = l.find((i) => i.id === this._lead.id);
          if (this._lead) {
            this._notes = this._lead.conversation.notes;
            this._phone = new ContactNumber(this._lead.contactPhone);
            this._email = new ContactEmail(this._lead.contactEmail);
            this._address = new ContactAddress(this._lead.contactAddress);
            this._editlead = new Lead(this._lead);
            this._editlead.attention = Boolean(
              Number(this._editlead.attention)
            );
          }
        });
    } else {
      this.store
        .select(fromSessionSelectors.SelectPipeline)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((l) => {
          this._lead = l.find((i) => i.id === this._lead.id);
          if (this._lead) {
            this._notes = this._lead.conversation.notes;
            this._phone = new ContactNumber(this._lead.contactPhone);
            this._email = new ContactEmail(this._lead.contactEmail);
            this._address = new ContactAddress(this._lead.contactAddress);
            this._editlead = new Lead(this._lead);
            this._editlead.attention = Boolean(
              Number(this._editlead.attention)
            );
          }
        });
    }

    this.store.dispatch(
      SessionActions.lazyLoadConversation({ lead: this._lead })
    );

    this.store
      .select(fromSessionSelectors.SelectDisplayedLeadTab)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((t) => {
        this._selectedButton = t;
        if (this._selectedButton === "history") {
          // this.store.dispatch(SessionActions.lazyLoadConversation({ lead: this._lead }));
          this.scrollToBottomOfHistory();
        } else if (this._selectedButton === "message") {
          // this.store.dispatch(SessionActions.lazyLoadConversation({ lead: this._lead }));
          this.scrollToBottomOfMessages();
        } else if (this._selectedButton === "edit") {
          this.store.dispatch(
            SessionActions.LazyLoadLeadDetail({ lead: this._lead })
          );
        }
      });

    this.updates$
      .pipe(ofType(SessionActions.LogoutUser), takeUntil(this.ngUnsubscribe))
      .subscribe((props) => {
        this.dismiss();
      });

    this.updates$
      .pipe(
        ofType(SessionActions.CloseLeadSuccess),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((props) => {
        this.dismiss();
      });

    this.updates$
      .pipe(
        ofType(SessionActions.NewTextMessage),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe({
        complete: () => {},
        error: (err) => {
          console.log(err);
        },
        next: () => {
          this.scrollToBottomOfMessages();
        },
      });

    this.wsMessagingService.joinRoom(this._lead.conversation.id);

    /* this.wsMessagingService.messages$.pipe(
      takeUntil(this.ngUnsubscribe),
      catchError(error => { throw error; }),
      tap({
        next: (msg) => {
          const message = new Message(msg as object);
          this.store.dispatch(SessionActions.NewTextMessage({ message }));
          if (message.message_direction === 'IN') {
            const audio = new Audio('./../../../assets/sounds/juntos-607.mp3');
            audio.play();
          }
          this.scrollToBottomOfMessages();
        },
        error: error => console.log('[Lead Accordian component] Error:', error),
        complete: () => console.log('[Lead Accordian component] Connection Closed'),
      })
    ).subscribe(); */
  }

  ngOnInit() {}

  @HostListener("window:popstate", ["$event"])
  dismiss() {
    this.store.dispatch(SessionActions.DisplayLead({ lead: null }));
    this.modalController.dismiss();
  }

  async scrollToBottomOfMessages() {
    setTimeout(async () => {
      const messagearea = document.getElementById("messagearea");
      messagearea.scrollIntoView({ behavior: "smooth", block: "end" });

      this.messagetaElement.setFocus();
      const nt = await this.messagetaElement.getInputElement();
      nt.focus();
      nt.setSelectionRange(0, 0);
    }, 100);
  }

  async scrollToBottomOfHistory() {
    setTimeout(() => {
      const historyarea = document.getElementById("historyarea");
      historyarea.scrollIntoView({ behavior: "smooth", block: "end" });
      // this.historyarea.nativeElement.scrollTop = this.historyarea.nativeElement.scrollHeight;
    }, 100);
  }

  async changeTab(tab: string) {
    this.store.dispatch(SessionActions.DisplayLeadTab({ tab }));
  }

  async call() {
    this.store.dispatch(SessionActions.CallLead({ lead: this._lead }));
  }

  async updateLead() {
    this.store.dispatch(
      SessionActions.UpdateLead({
        lead: this._editlead,
        phone: this._phone,
        email: this._email,
        address: this._address,
      })
    );
    this.changeTab("notes");
  }

  async updateScheduled(event) {
    this.store.dispatch(
      SessionActions.UpdateLeadSchedule({
        lead: this._editlead,
        date: event.detail.value,
      })
    );
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: LaPopoverComponent,
      componentProps: {
        lead: this._lead,
        isPipeline: this._isPipeline,
      },
      event: ev,
    });

    popover.onDidDismiss().then((result) => {
      if (result.data === "close") {
        this.dismiss();
      }
    });

    return await popover.present();
  }

  playvm(id: string) {
    console.log(id);

    if (this.vm) {
      this.vm.pause();
    }

    this.vm = document.getElementById(id) as HTMLMediaElement;
    this.vm.onplaying = (event) => {
      const playbtn = document.getElementById(
        "play-" + (event.target as HTMLAudioElement).id
      ) as HTMLButtonElement;
      const pausebtn = document.getElementById(
        "pause-" + (event.target as HTMLAudioElement).id
      ) as HTMLButtonElement;
      playbtn.hidden = true;
      pausebtn.hidden = false;
    };
    this.vm.onended = (event) => {
      const playbtn = document.getElementById(
        "play-" + (event.target as HTMLAudioElement).id
      ) as HTMLButtonElement;
      const pausebtn = document.getElementById(
        "pause-" + (event.target as HTMLAudioElement).id
      ) as HTMLButtonElement;
      playbtn.hidden = false;
      pausebtn.hidden = true;
    };
    this.vm.onpause = (event) => {
      const playbtn = document.getElementById(
        "play-" + (event.target as HTMLAudioElement).id
      ) as HTMLButtonElement;
      const pausebtn = document.getElementById(
        "pause-" + (event.target as HTMLAudioElement).id
      ) as HTMLButtonElement;
      playbtn.hidden = false;
      pausebtn.hidden = true;
    };
    this.vm.play();
  }

  pausevm() {
    if (this.vm) {
      this.vm.pause();
    }
  }

  convDate(dateString: string): Date {
    return new Date(dateString.replace("-", "/"));
  }

  async closelead() {
    if (this.closedata.disposition == null) {
      const toast = await this.toastController.create({
        message: "Please select a disposition",
        duration: 3000,
        position: "top",
        color: "primary",
      });
      toast.present();
    } else if (
      this.closedata.disposition === "won" &&
      this.closedata.product == null
    ) {
      // display message telling the user to select a product.
      const toast = await this.toastController.create({
        message: "Please Select a product",
        duration: 3000,
        position: "top",
        color: "primary",
      });
      toast.present();
    } else {
      this.store.dispatch(
        SessionActions.CloseLead({
          lead: this._lead,
          closeData: this.closedata,
        })
      );
      // this.dismiss();
    }
  }

  async introMessage() {
    this.conversationService
      .getIntroMessageText(this._lead.conversation)
      .pipe(first())
      .subscribe((resp) => {
        if (resp.result === true) {
          this._message = resp.data as unknown as string;
          // this.sendMessage();
        }
      });
  }

  async sendMessage() {
    // this.socket.emit('message', this._message );
    const msg = new Message({
      id: "",
      date_created: Date().toString(),
      message_direction: "OUT",
      conversation_id: this._lead.conversation.id,
      message_text: this._message,
      date_edited: Date(),
    });

    this._message = "";

    this.messageService
      .sendMessage(msg)
      .pipe(first())
      .subscribe((message) => {
        this._message = "";
      });
  }

  async editNotes() {
    this._editNotes = true;
    this._addNote = false;
    setTimeout(async () => {
      this.editnoteElement.setFocus();
      const nt = await this.editnoteElement.getInputElement();
      nt.focus();
      nt.setSelectionRange(0, 0);
    }, 200);
  }

  async updateNotes() {
    this.store.dispatch(
      SessionActions.UpdateConversationNote({
        lead: this._lead,
        note: this._notes,
      })
    );
    this._editNotes = false;
  }

  async cancelNotesEdit() {
    this._notes = this._lead.conversation.notes;
    this._editNotes = false;
    this._addNote = false;
  }

  async addNote() {
    this._newNote = "";
    this._editNotes = false;
    this._addNote = true;
    this.newnoteElement.setFocus();
  }

  async saveNote() {
    const tmpDate = new Date();

    this._notes =
      tmpDate.toLocaleDateString("en-US") +
      " - " +
      this._newNote +
      (this._notes === null || this._notes === ""
        ? ""
        : "\n\n\n" + this._notes);

    this.store.dispatch(
      SessionActions.UpdateConversationNote({
        lead: this._lead,
        note: this._notes,
      })
    );
    this._newNote = "";
    this._addNote = false;
  }

  onRateChange = ($event) => {
    this.store.dispatch(
      SessionActions.UpdateLeadCloseProbability({
        lead: this._editlead,
        probability: $event,
      })
    );
  };

  updateProjectedClose = ($event) => {
    this.store.dispatch(
      SessionActions.UpdateLeadExpectedCloseDate({
        lead: this._editlead,
        date: $event.detail.value,
      })
    );
  };

  updatecloseScheduled = ($event) => {
    this.closedata.scheduled = $event.detail.value;
  };

  formattedDate(unFormated: Date): string {
    if (unFormated == null) {
      return null;
    }
    const dt = new Date(unFormated);
    return dt.toISOString();
  }
}
