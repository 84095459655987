import { map } from 'rxjs/operators';

export class ListData {
    public total: number;
    public list: object[];
    public returned: number;

    constructor(values: any = {}) {
        Object.assign(this, values);
    }
}
