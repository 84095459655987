export const environment = {
  production: true,
  apiUrl: 'https://api.jabrly.com',
  websocketURL: 'wss://q6vtvq5ne5.execute-api.us-east-1.amazonaws.com/production',
  websocketReconnectInterval: 1000,
  firebase: {
    apiKey: 'AIzaSyBKyCLHEUidp5irfyCDyMpbBlzN2dSbdd8',
    authDomain: 'jabrly.firebaseapp.com',
    databaseURL: 'https://jabrly.firebaseio.com',
    projectId: 'jabrly',
    storageBucket: 'jabrly.appspot.com',
    messagingSenderId: '406011568244',
    appId: '1:406011568244:web:ba8b898c265395f37ab121',
    measurementId: 'G-XMFHZTCCSJ',
    vapidKey: 'BHHqKtdkb4NrD94d1anGMYw3yWbuazT-SeeuhuLVIOUIfVoJ7pdhueBFmVUM2JWJYNMRPAUUZZo66Rk8E8hrig4'
  }
};
