import { environment } from 'src/environments/environment';
import { Message } from 'src/app/classes/message/message';
import { Conversation } from './../../../classes/conversation/conversation';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ListData } from '../../../classes/list-data/list-data';
import { RestResponse } from '../../../classes/rest-response/rest-response';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private httpClient: HttpClient) { }

  public getMessages(conversation: Conversation): Observable<ListData> {
    return this.httpClient
      .get(environment.apiUrl + '/rest/conversation/' + conversation.id + '/messages', { withCredentials: true })
      .pipe(map((resp: RestResponse) => {
        const listdata: ListData = new ListData(resp.data);
        return listdata;
      }));
  }

  public sendMessage(message: Message): Observable<boolean> {
    return this.httpClient
      .post<object>(environment.apiUrl + '/rest/conversation/' + message.conversation_id + '/message',
       {message} , { withCredentials: true })
      .pipe(map((resp: RestResponse) => {
        return resp.result;
      }));
  }

  public getChatMessages(conversation: Conversation): Observable<ListData> {
    return this.httpClient
      .get(environment.apiUrl + '/rest/chat/' + conversation.id + '/messages', { withCredentials: true })
      .pipe(map((resp: RestResponse) => {
        const listdata: ListData = new ListData(resp.data);
        return listdata;
      }));
  }

  public sendChatMessage(message: Message): Observable<boolean> {
    return this.httpClient
      .post<object>(environment.apiUrl + '/rest/chat/' + message.conversation_id + '/message',
       {message} , { withCredentials: true })
      .pipe(map((resp: RestResponse) => {
        return resp.result;
      }));
  }
}
