import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { EMPTY, observable, Observable, Subject, timer } from "rxjs";
import {
  catchError,
  delayWhen,
  retryWhen,
  switchAll,
  tap,
} from "rxjs/operators";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { environment } from "src/environments/environment";
import { AppState } from "../ngrx";
import * as SessionActions from "src/app/ngrx/session/session.actions";

@Injectable({
  providedIn: "root",
})
export class WsMessagingService {
  private socket$: WebSocketSubject<any>;
  private messagesSubject$ = new Subject();
  public messages$: Observable<any>;

  constructor(private store: Store<AppState>) {}

  private getNewWebSocket() {
    return webSocket({
      url: environment.websocketURL,
      openObserver: {
        next: () => {
          // console.log('[WebSocket]: connection ok');
          this.store.dispatch(SessionActions.WSConnectionOpen());
        },
      },
      closeObserver: {
        next: () => {
          // console.log('[WebSocket]: connection closed');
          this.store.dispatch(SessionActions.WSConnectionClosed());
          this.socket$ = undefined;
          this.connect({ reconnect: true });
        },
      },
    });
  }

  public connect(cfg: { reconnect: boolean } = { reconnect: false }): void {
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.getNewWebSocket();
      this.messages$ = this.socket$.pipe(
        cfg.reconnect ? this.reconnect : (o) => o,
        tap({
          error: (error) => console.log(error),
        }),
        catchError((_) => EMPTY)
      );
      this.messagesSubject$.next(this.messages$);
    }
  }

  private reconnect(connection: Observable<any>): Observable<any> {
    return connection.pipe(
      retryWhen((errors) =>
        errors.pipe(
          tap((val) => console.log("[WebSocket] Try to reconnect", val)),
          delayWhen((_) => timer(environment.websocketReconnectInterval))
        )
      )
    );
  }

  public sendMessage(msg: any) {
    if (this.socket$ !== undefined) {
      this.socket$.next({ action: "message", data: msg });
    }
  }

  public joinRoom(room: string) {
    if (this.socket$ !== undefined) {
      this.socket$.next({ action: "join-room", data: room });
    }
  }

  public leaveRoom(room: string) {
    if (this.socket$ !== undefined) {
      this.socket$.next({ action: "leave-room", data: room });
    }
  }

  public close() {
    if (this.socket$ !== undefined) {
      this.socket$.complete();
    }
  }
}
