import { RestResponse } from './../../../classes/rest-response/rest-response';
import { environment } from 'src/environments/environment';
import { Conversation } from './../../../classes/conversation/conversation';
import { ListData } from '../../../classes/list-data/list-data';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConversationsService {

  constructor(private httpClient: HttpClient) { }

  public getConversations(): Observable<ListData> {
    return this.httpClient
      .get(environment.apiUrl + '/rest/conversations', { withCredentials: true })
      .pipe(map((resp: RestResponse) => {
        const listdata: ListData = new ListData(resp.data);
        return listdata;
      }));
  }

  public getConversationById(id: string ): Observable<Conversation> {
    return this.httpClient
      .get(environment.apiUrl + '/rest/conversation/' + id , { withCredentials: true })
      .pipe(map((resp: RestResponse) => {
        const conversation: Conversation = new Conversation(resp.data);
        return conversation;
      }));
  }

  public getConversationHistory(conversation: Conversation): Observable<ListData> {
    return this.httpClient
      .get(environment.apiUrl + '/rest/conversation/' + conversation.id + '/history', { withCredentials: true })
      .pipe(map((resp: RestResponse) => {
        const listdata: ListData = new ListData(resp.data);
        return listdata;
      }));
  }

  public updateConversation(conversation: Conversation): Observable<RestResponse> {
    return this.httpClient
    .post<object>(environment.apiUrl + '/rest/conversation/' + conversation.id ,
      JSON.stringify({ conversation }), { withCredentials: true })
    .pipe(map((resp: RestResponse) => {
      return resp;
    }));
  }

  public updateNotes(conversation: Conversation, notes: string): Observable<RestResponse> {
    return this.httpClient
    .post<object>(environment.apiUrl + '/rest/conversation/' + conversation.id + '/notes' ,
      { notes }, { withCredentials: true })
    .pipe(map((resp: RestResponse) => {
      return resp;
    }));
  }

  public getIntroMessageText(conversation: Conversation): Observable<RestResponse> {
    return this.httpClient
    .get<RestResponse>(environment.apiUrl + '/rest/conversation/' + conversation.id + '/textintro',
    { withCredentials: true });
  }

}
